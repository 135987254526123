import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"

const TermsAndConditionsPage = () => {
  return (
    <Layout>
      <SEO title="Terms &amp; Conditions" />
      <Hero pageTitle="Terms &amp; Conditions" />
      <section className="section-1 highlights image-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <p>
                By accessing this website, you are agreeing to be bound by these
                website Terms and Conditions of Use, all applicable laws and
                regulations, and agree that you are responsible for compliance
                with any applicable local laws. If you do not agree with any of
                these terms, you are prohibited from using or accessing this
                site. The materials contained in this website are protected by
                applicable copyright and trademark law.
              </p>
              <h5>Use License</h5>
              <p>
                Permission is granted to temporarily download one copy of the
                materials (information or software) on R.A. Wiedemann &amp;
                Associates' website for personal, non-commercial transitory
                viewing only. This is the grant of a license, not a transfer of
                title, and under this license you may not:
              </p>
              <ul>
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software
                  contained on R.A. Wiedemann &amp; Associates' website;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </li>
                <li>
                  transfer the materials to another person or “mirror” the
                  materials on any other server.
                </li>
              </ul>
              <p>
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by R.A. Wiedemann &amp;
                Associates at any time. Upon terminating your viewing of these
                materials or upon the termination of this license, you must
                destroy any downloaded materials in your possession whether in
                electronic or printed format.
              </p>
              <h5>Disclaimer</h5>
              <p>
                The materials on R.A. Wiedemann &amp; Associates' website are
                provided “as is”. R.A. Wiedemann &amp; Associates makes no
                warranties, expressed or implied, and hereby disclaims and
                negates all other warranties, including without limitation,
                implied warranties or conditions of merchantability, fitness for
                a particular purpose, or non-infringement of intellectual
                property or other violation of rights. Further, R.A. Wiedemann
                &amp; Associates does not warrant or make any representations
                concerning the accuracy, likely results, or reliability of the
                use of the materials on its website or otherwise relating to
                such materials or on any sites linked to this site.
              </p>
              <h5>Limitations</h5>
              <p>
                In no event shall R.A. Wiedemann &amp; Associates or its
                suppliers be liable for any damages (including, without
                limitation, damages for loss of data or profit, or due to
                business interruption,) arising out of the use or inability to
                use the materials on R.A. Wiedemann &amp; Associates' website,
                even if R.A. Wiedemann &amp; Associates or a R.A. Wiedemann
                &amp; Associates authorized representative has been notified
                orally or in writing of the possibility of such damage. Because
                some jurisdictions do not allow limitations on implied
                warranties, or limitations of liability for consequential or
                incidental damages, these limitations may not apply to you.
              </p>
              <h5>Revisions and Errata</h5>
              <p>
                The materials appearing on R.A. Wiedemann &amp; Associates' web
                site could include technical, typographical, or photographic
                errors. R.A. Wiedemann &amp; Associates does not warrant that
                any of the materials on its web site are accurate, complete, or
                current. R.A. Wiedemann &amp; Associates may make changes to the
                materials contained on its web site at any time without notice.
                R.A. Wiedemann &amp; Associates does not, however, make any
                commitment to update the materials.
              </p>
              <h5>Site Terms of Use Modifications</h5>
              <p>
                R.A. Wiedemann &amp; Associates may revise these terms of use
                for its website at any time without notice. By using this
                website you are agreeing to be bound by the then current version
                of these Terms &amp; Conditions of Use.
              </p>
              <h5>Governing Law</h5>
              <p>
                Any claim relating to R.A. Wiedemann &amp; Associates' website
                shall be governed by the laws of the State of Kentucky without
                regard to its conflict of law provisions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsAndConditionsPage
